import Base from './base';
import App from './app';
import Util from './util';
import $ from 'jquery';
import _ from 'underscore';
import User from './user';


/** @namespace Server */
var Server = {};

function convertQuadArraysToObjects(result) {
  if (!result) {
    return {};
  } else {
    return result.map(function (tripleRow) {
      return {
        subj: tripleRow[0],
        pred: tripleRow[1],
        obj: tripleRow[2],
        graph: tripleRow[3],
      };
    });
  }
}

function convertQuintArraysToObjects(result) {
  if (!result) {
    return {};
  } else {
    return result.map(function (tripleRow) {
      return {
        tripleId: tripleRow[0],
        subj: tripleRow[1],
        pred: tripleRow[2],
        obj: tripleRow[3],
        graph: tripleRow[4],
      };
    });
  }
}

/**
 * Prepares a query request to be sent to the server.
 *
 * Used by sendQueryRequest.
 *
 * @param {string} type - one of: "AUDIT", "SPARQL", "Prolog"
 * @param {string|Object} query - either the query string
 *   (if type is SPARQL or Prolog), or a configuration object for AUDIT.
 *   See Query.loadQueryResults JSDoc for entries in AUDIT object.
 * @param {Object} [options] - additional query options.
 *  Entries:
 *   - {int} limit - results limit or null
 *   - {int} [offset] - paging offset (set by Query.loadMoreQueryRows)
 *   - {string} [uuid] - unique identifier of the query (random)
 *   - {boolean} [logQuery] - whether to return the query log
 *                             instead of results
 *   - {boolean} [returnPlan] - whether to return the query plan
 *                               instead of results
 * @return {Object} - parameters for the query:
 *  - {string} method - request method
 *  - {string} path - request path
 *  - {Object} queryArgs - parameters that will be converted to the query part
 *                         of the URL
 *  - {Object} requestArgs - additional parameters for the httpRequest call
 */
Server._prepareQueryRequest = function (type, query, options) {
  var limit = options.limit;
  var offset = options.offset;
  var logQuery = options.logQuery;
  var returnPlan = options.returnPlan;
  var cancelOnWarnings = App.cancelOnWarnings;
  var location;
  var method;
  var queryArgs;
  var requestArgs;
  if (type === 'AUDIT') {
    location = '/auditLog';
    method = 'GET';
    queryArgs = {
      startDate: query.startDate, endDate: query.endDate,
      events: query.events, users: query.users,
      limit: limit, offset: offset,
    };
    requestArgs = {
      accept: 'application/json',
    };
  } else {
    location = Base.serverUrl('');
    method = 'POST';
    queryArgs = {
      queryLn: type,
      limit: limit, offset: offset,
      infer: App.useReasoning, logQuery: logQuery, returnPlan: returnPlan,
      uuid: options.uuid, returnQueryMetadata: true,
      checkVariables: cancelOnWarnings,
    };
    requestArgs = {
      accept: 'application/json',
      body: { query: query },
      contentType: 'application/x-www-form-urlencoded',
    };
  }

  return {
    method: method,
    path: location,
    queryArgs: queryArgs,
    requestArgs: requestArgs,
  };
};

/**
 * Converts the result of a SPARQL, Prolog or Audit query to JSON format,
 * additionally adding header information from the response as keys of the
 * returned object (where applicable).
 *
 * @param {Async} async - the promise containing server response headers
 * @param {string|null|undefined} input - what the server responded in body
 * @return {Object|null}
 */
Server._convertQueryResult = function (async, input) {
  if (!input) {
    return null;
  } else {
    var json = Util.readJSON(input);
    // The queryInfo key will have metadata if we get back rows (e.g.,
    // from SELECT), the response header will have slightly less up to
    // date metadata if we get back a boolean (e.g., from ASK or
    // UPDATE).
    var information = json.queryInfo ||
         Util.readJSON(decodeURIComponent(
              async.other.getResponseHeader('x-query-info') || '')) || '';

    return makeUnifiedQueryResult(json, information);
  }
};

/**
 * Converts data returned from the server (which can be almost any JSON object)
 * to a unified object format.
 *
 * @param {*} result - what the server returned
 * @param {*} information - additional information from the response header
 * @return {Object} - query result in a unified format.
 *  Guaranteed keys returned:
 *   - {Array} values - the actual values returned in the query result
 *   - {string} resultType - type of result as described
 *                           by Server._detectQueryResultType
 *   - {*} information - additional information from the response header
 */
function makeUnifiedQueryResult(result, information) {
  var resultType = Server._detectQueryResultType(result);
  // First, make sure the result is an Object with a "values" key:
  if (resultType === 'boolean') {
    // The server returned just a boolean.
    // Let's wrap it in a one-element array:
    result = {
      values: [result],
    };
  } else if (resultType === 'triples') {
    // The server returned an array of arrays.
    result = {
      values: convertQuadArraysToObjects(result),
    };
  } else if ((resultType === 'table') && (result.values === undefined)) {
    // The server returned empty results.
    result.values = [];
  }
  // Add some metadata:
  $.extend(result, {
    resultType: resultType,
    information: information,
  });
  return result;
}

Server._detectQueryResultType = function (result) {
  if (typeof result === 'boolean') {
    return 'boolean';
  }
  if (_.isArray(result)) {
    return 'triples';
  }
  if (_.isObject(result)) {
    if (result.hasOwnProperty('log')) {
      return 'log';
    }
    if (result.hasOwnProperty('plan')) {
      return 'plan';
    }
    if (result.hasOwnProperty('names')) {
      return 'table';
    }
  }
  throw new Error('Unrecognized result type: ' + result);
};

// eslint-disable-next-line valid-jsdoc
/**
 * Sends a query to the server, returning a promise of results.
 *
 * Called directly from Query.loadQueryResults or, with a different options
 * object, from Query.loadMoreQueryRows.
 *
 * See Server._prepareQueryRequest for parameter descriptions.
 *
 * @return {Async} - Promise of a parsed JSON response from the server,
 *   with additional keys added by Query.convertQueryResult.
 */
Server.sendQueryRequest = function (type, query, options) {
  var request = Server._prepareQueryRequest(type, query, options);
  var async = Base.req(
    request.method, Base.url(request.path, request.queryArgs),
    request.requestArgs);
  return async.transform(Server._convertQueryResult.bind(Server, async));
};

Server.getStatements = function (args) {
  return Base.jsonReq('GET', Base.url('statements', args), {
    accept: 'application/x-quints+json',
  }).transform(convertQuintArraysToObjects);
};

Server.deleteStatements = function (tripleIds) {
  var args = {
    ids: true,
  };
  return Base.req('POST', Base.url('statements/delete', args), {
    contentType: 'application/json',
    body: Util.writeJSON(tripleIds),
  });
};

Server.freetextSearch = function (args) {
  return Base.jsonReq('GET', Base.url('freetext', args), {
    accept: 'application/x-quints+json',
  }).transform(convertQuintArraysToObjects);
};

/**
 * Triggers a download, allowing the user to save query results to disk.
 *
 * @param {Object} params - describes what should be downloaded.
 *  Entries:
 *   - {string} file - Suggested name for the downloaded file.
 *   - {string} accept - Value for the 'accept' header.
 *   - {string} path - Path to download from.
 */
Server.download = function (params) {
  var requestParams = {};

  // Make a copy since we need to remove the path (and move it to the body)
  Object.assign(requestParams, params);
  delete requestParams.path;
  var form = document.createElement('form');

  // add path to the request body
  var path = document.createElement('input');
  path.setAttribute('name', 'path');
  path.setAttribute('value', params.path);
  form.appendChild(path);

  // setup the request
  form.setAttribute('method', 'POST');
  form.setAttribute('action', Base.serverUrl('/download', requestParams));

  // add the auth params to the request body
  var authField = document.createElement('input');
  authField.setAttribute('name', 'auth-token');
  authField.setAttribute('value', User.getAuthToken());
  form.appendChild(authField);

  // I think this is not required, at least in modern browsers,
  // but better safe than spr...
  form.style.display = 'none';
  document.body.appendChild(form);

  form.submit();

  // MDN docs suggest that it is safe to detach the form
  // at this point...
  document.body.removeChild(form);
};

export default Server;
