import Dispatch from '../../dispatch';
import Query from '../../query';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>Recent queries</h2>\n\n");
Mold.forDepth++;
Mold.forEach($arg, function(query, $i) {
__out.push("\n  <h3><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query/' + query.id) ));
__out.push("\">");
__out.push(Mold.escapeHTML(query.title || ("Query #" + query.id)));
__out.push("</a>\n    <span class=\"notes\">\n      (");
__out.push(Mold.escapeHTML(query.type ));
__out.push(",\n       ");
if (query.unlimitedResults ) {
__out.push("\n        show all results)\n       ");
} else {
__out.push("\n        show ");
__out.push(Mold.escapeHTML(Query.DEFAULT_QUERY_CHUNK_SIZE));
__out.push(" results)\n       ");
}
__out.push("\n    </span>\n  </h3>\n  <pre class=\"query-text\">");
__out.push(Mold.escapeHTML(query.text));
__out.push("</pre>\n");
});
Mold.forDepth--;
__out.push("\n\n");
if (!$arg.length) {
__out.push("\n<p>You haven't made any queries yet in this session.</p>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;