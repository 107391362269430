import App from '../../app';
import Base from '../../base';
import Query from '../../query';
import User from '../../user';
import Store from '../../store';
import Indices from '../../indices';
import Dispatch from '../../dispatch';
import Util from '../../util';
import { $$ } from '../../util';
import $ from 'jquery';

import infoTemplate from './info.mold';
import gruffIcon from '../../../img/gruff-logo-50x50.png';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>\n  ");
if (App.currentSession) {
__out.push("\n    Session (");
__out.push(Mold.escapeHTML(App.currentSession.name));
__out.push(")\n  ");
} else {
__out.push("\n    ");
if ($arg.distributed) {
__out.push("\n      Distributed repository ");
__out.push(Mold.escapeHTML(App.currentRepository));
__out.push("\n    ");
} else if ($arg.mmrRunning === "controlling" || $arg.mmrRunning === "yes") {
__out.push("\n      Replicated repository ");
__out.push(Mold.escapeHTML(App.currentRepository));
__out.push("\n    ");
} else {
__out.push("\n      Repository ");
__out.push(Mold.escapeHTML(App.currentRepository));
__out.push("\n    ");
}
__out.push("\n    ");
if (App.isInCatalog()) {
__out.push("\n      <span class=\"subtle\">in catalog <a href=\"..\">");
__out.push(Mold.escapeHTML(App.currentCatalog));
__out.push("</a></span>\n    ");
}
__out.push("\n  ");
}
__out.push("\n  &mdash; <span id=\"repo-statement-count\">");
__out.push(Mold.escapeHTML($arg.size));
__out.push("</span>\n</h2>\n\n<div id=\"repo-info\">\n ");
if (App.currentSession) {
__out.push("\n   <p>");
__out.push(Mold.escapeHTML(Base.capitalise(App.currentSession.description)));
__out.push(".</p>\n ");
} else {
__out.push("\n   ");
__out.push(infoTemplate.template($arg.info));
__out.push("\n ");
}
__out.push("\n</div>\n\n<div class=\"layout-row\">\n<div class=\"layout-column\">\n\n");
if (User.userAccess("W")) {
__out.push("\n<h3>Load and Delete Data</h3>\n<ul>\n<li><span class=\"linkish\">Add a statement</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.addStatementDialog();
});}) + "\"></var>");
__out.push("</li>\n<li><span class=\"linkish\">Delete statements</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.deleteStatementDialog();
});}) + "\"></var>");
__out.push("</li>\n<li>Import RDF:\n  <ul>\n    <li><span class=\"linkish\">from an uploaded file</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.uploadDialog();
});}) + "\"></var>");
__out.push("</li>\n    ");
if (User.userPerm("e")) {
__out.push("<li><span class=\"linkish\">from a server-side file</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.fileLoadDialog();
});}) + "\"></var>");
__out.push("</li>");
}
__out.push("\n    <li><span class=\"linkish\">from a text area input</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.textareaDialog();
});}) + "\"></var>");
__out.push("</li>\n  </ul>\n</li>\n</ul>\n");
}
__out.push("\n\n<h3>Explore the Repository</h3>\n<div id=\"explore-the-repository\">\n<ul>\n    ");
Mold.forDepth++;
Mold.forEach($arg.exploreLinks, function(link, $i) {
__out.push("\n      <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query/d/' + Util.encodeFragment(link.query)) ));
__out.push("\">");
__out.push(Mold.escapeHTML(link.title ));
__out.push("</a></li>\n    ");
});
Mold.forDepth--;
__out.push("\n    ");
if (!App.currentSession || App.currentSession.simple) {
__out.push("\n      <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('gruff') ));
__out.push("\">Explore repository in Gruff</a></li>\n    ");
}
__out.push("\n</ul>\n</div>\n\n\n");
if ($arg.queries && $arg.queries.length) {
__out.push("\n  <h3>Pre-defined queries</h3>\n  <ul>\n    ");
Mold.forDepth++;
Mold.forEach($arg.queries, function(q, $i) {
__out.push("\n      <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query/r/' + Util.encodeFragment(q)) ));
__out.push("\">");
__out.push(Mold.escapeHTML(q));
__out.push("</a>\n        ");
if (User.userPerm("super")) {
__out.push("\n          <span class=\"linkish\" title=\"delete\">×</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.deleteRepoQuery(q);
});}) + "\"></var>");
__out.push("\n        ");
}
__out.push("\n      </li>\n    ");
});
Mold.forDepth--;
__out.push("\n  </ul>\n");
}
__out.push("\n\n");
if (($arg.showMMRLinks || $arg.showWSLinks ) && !App.currentSession ) {
__out.push("\n  <h3>Replication</h3>\n  <ul>\n  ");
if ($arg.showMMRLinks ) {
__out.push("\n  <li>");
if ($arg.mmrRunning === "yes"
       ) {
__out.push("<a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('replmanage') ));
__out.push("\"\n            >Multi-master: manage replication instances</a>\n    ");
}
if ($arg.mmrRunning === "controlling"
            ) {
__out.push("<a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('replmanagecontrolling') ));
__out.push("\"\n                 >Multi-master: manage replication instances as controller</a>\n    ");
}
if ($arg.mmrRunning === "no"
            ) {
__out.push("<span class=\"linkish\">Multi-master: convert store to a replication instance</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.showConvertStoreDialog();
});}) + "\"></var>");
__out.push("\n    ");
}
__out.push("\n    ");
Mold.dispatchCustom("help", "\n      Multi-master replication (MMR) is a data replication solution\n      that allows data to be stored by a group of database instances,\n      and updated by any member of the group. All members are\n      responsive to client data queries. The multi-master replication\n      system is responsible for propagating the data modifications\n      made by each member to the rest of the group. See <a\n      target='_blank' class='linkish'\n      href='https://franz.com/agraph/support/documentation/current/multi-master.html'\n      >AllegroGraph MMR documentation</a> for more details.", __out);
__out.push("\n  </li>\n  ");
}
__out.push("\n  ");
if ($arg.showWSLinks ) {
__out.push("\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('replication') ));
__out.push("\">Warm standby: control replication</a>\n    ");
Mold.dispatchCustom("help", "\n      Warm standby is a data replication solution which maintains a set\n      of secondary ready-only backup systems that run in the background\n      of the primary system. Data is mirrored to the secondary servers\n      asynchronously, providing eventual consistency of data in all\n      database instances. See <a target='_blank' class='linkish'\n      href='https://franz.com/agraph/support/documentation/current/replication.html'\n      >AllegroGraph replication documentation</a> for more\n      details. Note that warm standby is the old AllegroGraph replication\n      system. <a target='_blank' class='linkish'\n      href='https://franz.com/agraph/support/documentation/current/multi-master.html'\n      >Multi-master replication</a> should be used instead.", __out);
__out.push("\n  </li>\n  ");
}
__out.push("\n  </ul>\n");
}
__out.push("\n\n<h3>Repository Control</h3>\n<ul>\n<li><span class=\"linkish\">Export</span> repository as\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.downloadRepository(Util.selectValue($$('download-type')));
});}) + "\"></var>");
__out.push("\n  <select id=\"download-type\">\n    ");
Mold.forDepth++;
Mold.forEach(Query.TRIPLE_CONTENT_TYPES, function(tp, $i) {
__out.push("\n      ");
if (tp[3]) {
__out.push("\n        <option value=\"");
__out.push(Mold.escapeHTML(tp[1]));
__out.push("\">");
__out.push(Mold.escapeHTML(tp[0]));
__out.push("</option>\n      ");
}
__out.push("\n    ");
});
Mold.forDepth--;
__out.push("\n  </select></li>\n");
if (User.userAccess("r") ) {
__out.push("\n<li><span class=\"linkish\">Warmup store</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.showWarmupDialog();
});}) + "\"></var>");
__out.push("</li>\n");
}
__out.push("\n");
if (User.userPerm("replication") && !App.currentSession) {
__out.push("\n  <li><span class=\"linkish\">Back-up</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.backupDialog();
});}) + "\"></var>");
__out.push(" this repository</li>\n");
}
__out.push("\n  <li><span class=\"linkish\">Export duplicate statements</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.downloadDuplicates(Util.selectValue($$('duplicate-mode')));
});}) + "\"></var>");
__out.push("\n    <select id=\"duplicate-mode\">\n       <option value=\"spog\">Subject, Predicate, Object and Graph (spog)</option>\n       <option value=\"spo\">Subject, Predicate, Object only (spo)</option>\n    </select></li>\n  ");
if (User.userAccess("W") ) {
__out.push("  \n    <li><span class=\"linkish\">Delete duplicate statements</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.showDuplicateDialog(); 
});}) + "\"></var>");
__out.push("</li>\n  ");
}
__out.push("\n  ");
if (User.userAccess("W") ) {
__out.push("  \n    <li><span class=\"linkish\">Suppress duplicate statements</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.showSuppressDuplicateDialog(); 
});}) + "\"></var>");
__out.push("\n    <span id=\"repo-suppress-duplicates\">");
__out.push(Mold.escapeHTML($arg.suppressDuplicates ));
__out.push("</span>\n  ");
}
__out.push("\n  </li>\n");
if (User.userPerm("super")) {
__out.push("\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('reports/transactions') ));
__out.push("\">View/manage active transactions</a></li>\n\n");
}
__out.push("\n\n  <li>Recognize geospatial datatypes automatically: <input class=\"centered-checkbox\" id=\"repoGeospatialDatatypeAutomation\" type=\"checkbox\"");
if ($arg.geospatialDatatypeAutomation ) {
__out.push(" checked");
}
if (! User.userAccess("W")) {
__out.push(" disabled");
}
__out.push(">\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.setGeospatialDatatypeAutomation(
          !($('#repoGeospatialDatatypeAutomation').attr('checked')));
});}) + "\"></var>");
__out.push("</li>\n\n");
if (App.isInRealRepo() && User.userAccess("W")) {
__out.push("\n  <li><span class=\"linkish\">Control durability (bulk-load mode)</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.bulkDialog($node);
});}) + "\"></var>");
__out.push("</li>\n");
if (App.isInRealRepo() && User.userPerm("super")) {
__out.push("\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('attribute-definitions') ));
__out.push("\">Manage triple attribute definitions and static filter</a></li>\n");
}
__out.push("\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('reports/indices') ));
__out.push("\">View/manage triple indices</a></li>\n  <li><span class=\"linkish\">Optimize the repository now</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
if ($node.disabled) { return; }
        $node.disabled = true;
        Base.loadWithDefaultErrorHandler(Indices.optimize(),
          'Optimizing').then(function () {
             $node.disabled = false;
          });
    
});}) + "\"></var>");
__out.push("\n    ");
Mold.dispatchCustom("help", "\n        This is useful when data has been added to the repository,\n        and you now want to query it without adding new data for a while.\n        Optimizing will organize and index the existing triples\n        for optimal query performance.\n        This may take a long time on big repositories.\n        For fine control of the indices to optimize and the amount\n        of optimization to perform click on \"View/manage triple indices\" above.", __out);
__out.push("\n  </li>\n  <!-- Automatic optimizer is only available for actual repositories\n       and is not supported for sessions. -->\n  ");
if (App.isInRealRepo() &&
  !App.currentSession) {
__out.push("\n  <li><span class=\"linkish\"><span id=\"startstopopt\">Start background Automatic Optimizer</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.autoOptimizerToggle(); 
});}) + "\"></var>");
__out.push("\n      </span><span id=\"autooptrunning\"></span>\n  </li>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){Store.setAutoOptState(); }) + "\"></var>");
__out.push("\n  <li><span class=\"linkish\">Show Automatic Optimizer message</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.autoOptimizerMessage(); 
});}) + "\"></var>");
__out.push("\n      ");
Mold.dispatchCustom("help", "\n         The message shows the state of each index in the repository.  The format\n\t is  'index oscore (deleted%) activity'.\n\t The deleted% is an estimate of the number of deleted triples still in\n\t in index.  'activity' tells of any current activity on the index.\n\t 'i' is most common meaning 'idle', that is, no activity.\n\t '2' means a level 2 optimization being done.  'p' is a purge of\n\t deleted triples.", __out);
__out.push("\n  </li>\n  ");
}
__out.push("\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('text-index') ));
__out.push("\">Manage free-text indices</a></li>\n  ");
if (User.userAccess("w")) {
__out.push("\n    <li><span class=\"linkish\">Materialize Entailed Triples</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.showMaterializeDialog();
});}) + "\"></var>");
__out.push("<span id=\"materializing\"></span>\n      ");
Mold.dispatchCustom("help", "\n          Materializing entailed triples adds inferences to the repository for answering certain reasoning queries\n          without query-time reasoning enabled.", __out);
__out.push("\n    </li>\n    <li><span class=\"linkish\">Delete Materialized Triples</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.deleteMaterializedDialog();
});}) + "\"></var>");
__out.push("</li>\n    <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('solr') ));
__out.push("\">Manage external Solr free-text indexer</a>\n        ");
Mold.dispatchCustom("help", "This link lets you set up a connection to the Apache Solr full-text indexer from an AllegroGraph repository.", __out);
__out.push("\n    </li>\n    <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('mongo') ));
__out.push("\">Manage external MongoDB connection</a>\n        ");
Mold.dispatchCustom("help", "This link lets you set up a connection to a MongoDB from an AllegroGraph repository.", __out);
__out.push("\n    </li>\n  ");
}
__out.push("\n");
}
__out.push("\n");
if (User.userAccess("w") && $arg.mmrRunning === "no"
   && App.isInRealRepo() && !App.currentSession ) {
__out.push("\n  <li><span class=\"linkish\">Shutdown instance</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.shutdownInstance();
});}) + "\"></var>");
__out.push("\n    ");
Mold.dispatchCustom("help", "Make the repository instance shut down when the instance is unused.\n            Clicking the link will also automatically go back to dashboard.", __out);
__out.push("\n  </li>\n");
}
__out.push("\n</ul>\n</div>\n\n<div class=\"layout-column\">\n\n<h3>Tools</h3>\n<ul class=\"tool-box\">\n  <li class=\"tool-link\">\n    <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('gruff') ));
__out.push("\">\n      <img id=\"gruff-button\" src=\"");
__out.push(Mold.escapeHTML(gruffIcon ));
__out.push("\" alt=\"Explore this repository using Gruff\"\n           data-toggle=\"tooltip\" title=\"Visualization, Exploration, Query Builder, and more...\"/></a>\n    ");
if (App.currentSession && !App.currentSession.simple) {
__out.push("\n      Start Gruff\n      ");
Mold.dispatchCustom("help", "\n          Automatic Gruff connection to complex sessions is not\n          supported for now. Gruff will be started on a connection\n          dialog.", __out);
__out.push("\n    ");
} else {
__out.push("\n      Explore ");
__out.push(Mold.escapeHTML(App.currentRepository));
__out.push(" using Gruff\n    ");
}
__out.push("\n  </li>\n</ul>\n\n<h3>Reports</h3>\n<ul>\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('reports/storage') ));
__out.push("\">Storage report</a></li>\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('reports/indices') ));
__out.push("\">Triple indices</a></li>\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('reports/sstab') ));
__out.push("\">String table</a></li>\n  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('reports') ));
__out.push("\">Full list of reports ...</a></li>\n</ul>\n\n");
if (User.userPerm("session") && User.userAccess("w") && $arg.mmrRunning === "no"
   && App.isInRealRepo() ) {
__out.push("\n<h3>Session Control</h3>\n<ul>\n ");
if (!App.currentSession) {
__out.push("\n  <li><span class=\"linkish\">Start a session</span> (support transactions)\n     ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
App.createRepoSession();
});}) + "\"></var>");
__out.push("\n  </li>\n ");
}
__out.push("\n ");
if (App.currentSession ) {
__out.push("\n  ");
if (!App.currentSession.autoCommit) {
__out.push("\n    <li><span class=\"linkish\">Commit transaction</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.commitRepo();
});}) + "\"></var>");
__out.push("\n      ");
Mold.dispatchCustom("help", "Make persistent any changes made to the repository in this session.", __out);
__out.push("\n    </li>\n    <li><span class=\"linkish\">Rollback transaction</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.rollbackRepo();
});}) + "\"></var>");
__out.push("\n      ");
Mold.dispatchCustom("help", "Undo any changes made to the repository in this session.", __out);
__out.push("\n    </li>\n  ");
}
__out.push("\n  <li><span class=\"linkish\">Close session</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
App.closeSession();
});}) + "\"></var>");
__out.push("\n      ");
Mold.dispatchCustom("help", "Uncommited changes will be lost when the session is closed.", __out);
__out.push("\n  </li>\n ");
}
__out.push("\n</ul>\n");
}
__out.push("\n\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;