// This file is only included when building in dev mode.
// It contains debug utilities that should not be shipped
// with the final product.

// Note that this file is used as the entry point, so it must
// import the whole application.

import './index.js';

// This turns a path (of the form './some-thing.js') into a camel-cased
// name (SomeThing) that will be used to access the module from the browser
// console.
function pathToModuleName(path) {
  // Index one character after slash, or 0 if there is no slash.
  var slash = path.lastIndexOf('/') + 1;
  // Cut the directory and extension, replace - and _ with camel-case.
  // Also capitalize the first letter.
  return path.substr(slash, path.length - slash - '.js'.length)
      .replace(/^.|-.|_./g, function (match) {
        return match.slice(-1).toUpperCase();
      });
}

// Get a loader for js files from the current directory
var context = require.context('.', true, /\.js$/);
// For each file...
context.keys().forEach(function (key) {
  // Import the module...
  var module = context(key);
  // If it has the 'default' property (which means the module has
  // a default export) create a top-level variable for it.
  if (module.hasOwnProperty('default')) {
    // Values set on window will be accessible as globals in the console.
    window[pathToModuleName(key)] = module.default;
  }
});
